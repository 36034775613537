<template>
  <footer class="pb-6 pt-14 lg:pb-10 lg:pt-20">
    <div class="container grid grid-cols-6 gap-x-4 lg:grid-cols-12 lg:gap-x-8 lg:gap-y-16">
      <div class="col-span-6 lg:col-span-4">
        <Logo class="mb-5 block w-fit" />
        <div>
          <div class="flex">
            <div>
              <svg
                class="h-[61px] w-auto lg:h-auto"
                width="152"
                height="97"
                viewBox="0 0 152 97"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M146 97L151.312 86.7476L139.777 87.2732L146 97ZM0.0214472 1.206C4.96211 24.6758 16.1364 36.95 30.3249 43.2906C44.4084 49.5842 61.3517 49.9723 77.6855 49.9982C94.1353 50.0243 109.908 49.6908 122.091 54.3042C128.14 56.5949 133.264 60.0952 137.121 65.4535C140.984 70.8195 143.629 78.1206 144.594 88.0936L146.585 87.9008C145.595 77.6763 142.866 70.0102 138.744 64.285C134.617 58.552 129.146 54.837 122.799 52.4338C110.191 47.6595 93.9671 48.024 77.6887 47.9982C61.2943 47.9722 44.7773 47.5584 31.1409 41.4646C17.6096 35.4177 6.80445 23.7187 1.97855 0.794004L0.0214472 1.206Z"
                  fill="#080808"
                />
              </svg>
            </div>
          </div>
        </div>
        <MobileAppRedirects class="mt-6" is-small />

        <div class="mb-6 mt-5 text-sm-semibold lg:mt-10 lg:text-base-semibold">
          {{ $t('social.follow') }}
        </div>
        <Social class="w-full" />
      </div>
      <div class="col-span-6 lg:col-span-8"><SiteMap :data="data.allMenuSitemaps" /></div>
      <div class="col-span-6 lg:col-span-7">
        <hr class="mb-6 h-0.5 border-none bg-gray1 lg:hidden" />
        <small class="text-sm">{{ $t('copyright') }} ® {{ year }}</small>
      </div>
      <div class="col-span-6 lg:col-span-5">
        <FooterNav :data="data.allMenuFooters">
          <Language />
        </FooterNav>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
const year = new Date().getFullYear()

defineProps({
  data: {
    type: Object,
    default: () => {}
  }
})
</script>
