<template>
  <div class="flex w-fit">
    <div class="flex-1" v-for="item in links" :key="item.name">
      <a
        target="_blank"
        rel="noopener noreferrer"
        :href="item.href"
        :title="item.title"
        class="mr-10 lg:mr-14"
        ><Icon :name="item.icon" color="#CF4145"
      /></a>
    </div>
  </div>
</template>

<script setup lang="ts">
const { t } = useI18n()

const links = [
  {
    name: 'LinkedIn',
    href: 'https://www.linkedin.com/showcase/indoc-edge/?viewAsMember=true',
    icon: 'linkedin',
    title: t('social.links.linkedin')
  },
  {
    name: 'Facebook',
    href: 'https://www.facebook.com/mikrocop',
    icon: 'facebook',
    title: t('social.links.facebook')
  },
  {
    name: 'YouTube',
    href: 'https://www.youtube.com/@InDocEDGE',
    icon: 'youtube',
    title: t('social.links.youtube')
  },
  {
    name: 'Medium',
    href: 'https://medium.com/@mikrocop',
    icon: 'medium',
    title: t('social.links.medium')
  }
]
</script>
