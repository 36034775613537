<template>
  <div>
    <component
      :is="data.reference !== null ? NuxtLink : data.externalLink ? NuxtLink : 'span'"
      :to="
        data.reference
          ? localePathByType('PageRecord', data.reference.slug)
          : data.externalLink
          ? data.externalLink
          : undefined
      "
      noPrefetch
      class="mb-4 mt-6 block text-lg-semibold lg:mt-0 lg:mb-6 lg:text-2xl lg:font-semibold"
    >
      {{ data.title }}
    </component>
    <ul v-if="Array.isArray(data.children) && data.children.length" class="text-sm lg:text-base">
      <li v-for="item in data.children" :key="item.id">
        <NuxtLink
          :to="
            item.reference
              ? localePathByType('PageRecord', item.reference.slug)
              : item.externalLink
              ? item.externalLink
              : undefined
          "
          class="my-4 block w-fit"
          >{{ item.title }}
        </NuxtLink>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
const NuxtLink = resolveComponent('NuxtLink')
const { localePathByType } = useLocalePathByType()

defineProps({
  data: {
    type: Object,
    default: () => {}
  }
})
</script>
