<template>
  <nav v-if="data" class="mx-auto ml-0 w-fit lg:ml-auto lg:mr-0 lg:flex lg:items-center">
    <NuxtLink
      v-for="item in data"
      :key="item.id"
      :to="item.reference ? localePathByType('PageRecord', item.reference.slug) : item.externalLink"
      :target="item.externalLink && '_blank'"
      class="my-6 block w-fit text-sm lg:m-0 lg:mr-6 lg:inline-block"
    >
      {{ item.title }}
    </NuxtLink>
    <slot />
  </nav>
</template>

<script setup lang="ts">
import { MenuFooterRecord } from '~/types/generated'

defineProps({
  data: {
    type: Array as PropType<MenuFooterRecord[]>,
    default: () => []
  }
})

const { localePathByType } = useLocalePathByType()
</script>
