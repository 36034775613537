<template>
  <div ref="dropdown" class="relative flex items-center">
    <button
      id="languageToggler"
      class="inline-flex items-center justify-center gap-1 text-sm"
      @click="isActive = !isActive"
    >
      <Icon name="world" class="relative inline-block lg:left-auto lg:mr-1" />
      <span>{{ activeLocale }}</span>
      <Icon
        name="chevron-filled-down"
        size="sm"
        class="relative cursor-pointer"
        :color="isActive ? '#D70036' : '#080808'"
        :class="isActive ? 'rotate-180' : 'rotate-0'"
      />
    </button>

    <Transition name="fade">
      <ul
        v-if="isActive"
        class="absolute bottom-[calc(100%+12px)] left-0 min-w-[160px] bg-white p-4 shadow-lg lg:left-auto lg:right-0"
        aria-labelledby="languageToggler"
      >
        <li
          v-for="item in locales"
          :key="item.code"
          class="mt-2 inline-block w-full text-black first:mt-0"
        >
          <button
            :hreflang="item.code"
            class="w-full px-2 py-1 text-left text-sm"
            :class="{ 'bg-red text-white': item.code === locale }"
            :disabled="item.code === locale"
            @click="switchLanguage(item.code)"
          >
            {{ item.name }}
          </button>
        </li>
      </ul>
    </Transition>
  </div>
</template>

<script setup lang="ts">
import { LocaleObject } from '@nuxtjs/i18n/dist/runtime/composables'
import { theme } from '#tailwind-config'

const { locale, locales } = useI18n()
const isActive = ref(false)

const dropdown = ref(null)
onClickOutside(dropdown, () => hideDropdown())

const activeLocale = computed(
  () => locales.value.find((l: LocaleObject) => l.code === locale.value).name
)

function hideDropdown() {
  isActive.value = false
}

const switchLanguage = (lang: string) => {
  const language = useCookie('nf_country')
  language.value = lang

  if (lang === 'sl') {
    window.location.replace('/')
  } else {
    window.location.replace(`/${lang}`)
  }

  isActive.value = false
}
</script>
