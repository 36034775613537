<template>
  <nav class="my-10 grid grid-cols-6 gap-x-4 md:grid-cols-12 lg:m-0 lg:gap-x-8">
    <SiteMapItem v-for="item in parents" :key="item.id" :data="item" class="col-span-3" />
  </nav>
</template>

<script setup lang="ts">
const props = defineProps({
  data: {
    type: Object,
    default: () => {}
  }
})

// Get the top level menu items
const parents = computed(() => props.data.filter(item => item.parent === null))
</script>
